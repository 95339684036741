
.heading-title{
  font-size:26px;
  font-family:'Raleway', sans-serif;
  text-transform:uppercase;
  border-bottom:solid 1px #ddd;
  padding-bottom:10px;
}

p{
  font-size:16px;
  font-family:'PT Sans', 'sans-serif';
  line-height:24px;
}

nav{
  border-bottom:solid 1px #eee;
}
